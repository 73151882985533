<template lang="pug">
  div(class="list-content pc_list orders-list-table")
    edit-cart-custom-diff-dialog(ref="editCartCustomDiffDialog" @childEvent="reloadCart")

    el-dialog(:visible.sync="centerDialogVisible" center title="Booking Comments")
      el-row
        el-col
          el-table(:data="comments[locator]")
            el-table-column(prop="backendUser.login")
            el-table-column
              template(slot-scope="scope")
                div(v-html="scope.row.data")
            el-table-column
              template(slot-scope="scope")
                span {{new Date(scope.row.createdAt) | moment("LLL")}}
      el-row(:gutter="20")
        el-col(:span="16")
          el-input(type="textarea" v-model="comment" :autosize="{ minRows: 2, maxRows: 4}")
        el-col(:span="6")
          el-button(@click="addComment" type="primary") Add Comment

      span.dialog-footer(slot="footer")
        el-button(@click="centerDialogVisible = false") Close

    el-dialog(:visible.sync="centerAlternativeDialogVisible" center :title="titleForAlternativesDialog" :close-on-click-modal="false" @close="closeAlternativesDialog")

      el-row
        el-col
          el-table(:data="[baseRecommendation]" stripe)

            el-table-column(label='-' width="50px")
            el-table-column(label="Status" width="200px" prop="statusStr")
            el-table-column(label="Config" prop="configName")
            el-table-column(label="Baggage" prop="baggage")
            el-table-column(label="Class")
              span -
            el-table-column(label="All PC's Formula Price" prop="formulaAmt")
            el-table-column(label='-' width="100px")

      el-row

      el-row
        el-col
          el-table(:data="getSortedAlternativeTableData(remoteRequestId)" border stripe :key="row.id" ref="alternativesFilterTable" max-height="250")

            el-table-column(label='ID' prop="id" width="50px")
            el-table-column(label="Segments" width="200px" :filters="filterItemsForSegments" :filter-method="filterMethodBySegments")
              template(slot-scope="scope")
                div(:id="scope.row.id")
                span {{segments_decorate(scope.row.segments)}}
            el-table-column(label="Config" prop="configName" :filters="filterDataFor('configName', remoteRequestId)" :filter-method="filterHandler")
            el-table-column(label="Baggage" prop="baggage" :filters="filterDataFor('baggage', remoteRequestId)" :filter-method="filterHandler")
            el-table-column(label="Class" prop="class" :filters="filterDataForClass(remoteRequestId)" :filter-method="filterAlternativeClass")
              template(slot-scope="scope")
                span {{class_decorate(scope.row.segments)}}
            el-table-column(label="PC Formula Price" prop="formualaAmt")

            el-table-column(width="100px")
              template(slot-scope="scope")
                el-button(type="primary" :disabled="disableAdding(bookingLocator)" @click="addToCart(scope.row, bookingLocator, currentPc)" :id="scope.row.id") Add to Cart

    el-dialog(:visible.sync="centerAlternativeMailDialogVisible" center :title="titleForEmailDialog" :close-on-click-modal="false")
      div(v-html="emailHtml")
      
      span.dialog-footer(slot="footer")
        el-button(@click="sendMailWithAlternatives") Send Mail
        el-button(@click="centerAlternativeMailDialogVisible = false") Close

    el-table(:data="records" :row-class-name="tableRowClassName" v-loading="loading" @expand-change="aviaBookingExpanded" :key="row.id")
      el-table-column
        template(slot-scope="scope")
          el-row
            el-col
              span.blue.bold.upcase Locator: {{scope.row.locator}}
          el-row
            el-col
            span.upcase {{generateTripRoute(scope.row.aviaBookings)}}
          el-row
            el-col
              span.bold.upcase DEP DATE {{ new Date(scope.row.aviaBookings[0].aviaBookingSegments[0].departureDate) | moment("DDMMMYY") }}
      el-table-column
        template(slot-scope="scope")
          el-row
            el-col
              span depot: {{ scope.row.depot.name }}
          el-row
            el-col
              span refid: {{ scope.row.trafficSource ? scope.row.trafficSource.name : "-" }}
          el-row
            el-col
              span Creation Date: {{ new Date(scope.row.orderedAt) | moment("L") }}
          el-row
            el-col
              span Issue Date: -
      el-table-column
        template(slot-scope="scope")
          el-row
            el-col.upcase
              span STATUS: &nbsp;
              span.blue {{ scope.row.booking && scope.row.booking.status }}
          el-row
            el-col
              span 0/{{scope.row.aviaBookings.length}} Complete
      el-table-column
        template(slot-scope="scope")
          el-row
            el-col
              span.bold.small Search: {{ getDepotTotal(scope.row.aviaBookings) }}
          el-row
            el-col
              span.bold.small {{ getRecommendedTotal() }}
          hr
          el-row
            el-col
              span.bold.small Issue: -
          el-row
            el-col
              span.bold.small Difference: -
      el-table-column
        template(slot-scope="scope")
          el-row
            el-col
              span Surcharge
          el-row
            el-col
              span {{scope.row.booking == null ? "No additional services" : (scope.row.booking.hasAncillaryServices ? "There are additional services" : "No additional services")}}
      el-table-column
        template(slot-scope="scope")
          span {{setComments(scope.row.booking, scope.row.locator)}}
          div(@click="showComments(scope.row.locator)").pointer
            el-row
              el-col
                span {{getLastCommentAuthor(scope.row.locator)}}:
            el-row
              el-col
                span.short {{getLastCommentText(scope.row.locator)}}

      el-table-column(type="expand")
        template(slot-scope="scope")

          el-card(v-if="!!cartItems[scope.row.locator] && cartItems[scope.row.locator].items.length > 0" v-loading="cartLoading[scope.row.locator]" class="box-card" :key="'cart-'+scope.row.locator")
            el-row
              el-col(:span="24")
                span.blue.bold.upcase Cart( {{cartItems[scope.row.locator].status}} ) : 
                el-button(:disabled="!isCartReadyForBooking(scope.row.locator)" @click="generateMailFromCart(scope.row.locator)") Generate Mail
                el-button(:disabled="!isCartReadyForBooking(scope.row.locator)" @click="cartBooking(scope.row.locator)") Book

            el-row( style="background-color: #F2F6FC" )
              el-table(:data="cartItems[scope.row.locator].items" :row-class-name="cartTableRowClassName")
                el-table-column(label='ID' prop="id" width="200px")
                  template(slot-scope="scope")
                    el-tooltip(effect='light' placement='top-start' content='FareDowngradeManualResult ID')
                      span {{scope.row.id}}
                el-table-column(label='Locator' prop='locator')
                el-table-column(label='status' prop='status')
                el-table-column(label='type' prop='type')
                el-table-column(label='segments' prop="leg" width="200px")
                el-table-column(label='stP' prop="stP")
                el-table-column(label='PC Formula Price' prop="price")
                el-table-column
                  template(slot-scope="scope")
                    el-button(v-if="scope.row.type == 'alternative_flight' && scope.row.status == 'created'" type="primary" @click="removeFromCard(scope.row)" :id="'remove-'+scope.row.id") remove
                    el-button(v-if="scope.row.status == 'undefined' && !disableNewSearchCartButton[scope.row.locator]" type="secondary" @click="newSearchFromCard(scope.row);" :id="'new-search-'+scope.row.id") New Search

            el-row
              el-table(:data="[cartItems[scope.row.locator].totals]")
                el-table-column(label="TOTAL" width="200px")
                el-table-column(label="Base All PC`s Formula Price" prop='base')
                el-table-column(label="Cart All PC`s Formula Price" prop='cart')
                el-table-column(label="Diff" prop='diff')
                  template(slot-scope="scope")
                    span {{scope.row.diff < 0 ? "+" : (scope.row.diff == 0 ? "" :"-")}} {{scope.row.diff*-1}}
                el-table-column(label="Custom Diff")
                  template(slot-scope="total_scope")
                    el-button(type="text" @click="$refs.editCartCustomDiffDialog.show(cartItems[scope.row.locator])") ... {{total_scope.row.custom_diff}}

          el-card(class="box-card" v-for="(aviaBooking) in scope.row.aviaBookings" :key="aviaBooking.id")
            el-row
              el-col(:span="8")
                el-row
                  span.bold Leg: &nbsp;
                  span {{generateTripRoute([aviaBooking])}}
                el-row
                  span.upcase.bold VC:&nbsp;
                  span {{getSupplier(aviaBooking)}}
                el-row
                  span.bold Dep. date:&nbsp;
                  span {{ parseDate(aviaBooking.departureTime) }}
                el-row
                  span.bold.upcase Pax:&nbsp;
                  span {{paxString(aviaBooking.aviaBookingPassengers)}}
                el-row
                  span PC Status:
                el-row
                  el-switch(active-text="Issue Blocked", inactive-text="Issue Allowed", v-model="issueance[aviaBooking.id]" @change="changeData(aviaBooking.id)")
              el-col(:span="4")
                el-row
                  span.blue Search Price: {{aviaBooking.depotCost.total}} {{aviaBooking.depotCost.currency}}

                el-row
                  span.blue Best Alternative Price: {{ aPrice[aviaBooking.id] }} {{aviaBooking.depotCost.currency}}
                el-row
                  span.blue New Price: {{ newPrice[aviaBooking.id] }} {{aviaBooking.depotCost.currency}}
                el-row(v-if='newPrice[aviaBooking.id] > 0')
                  span.blue Diff: {{ aviaBooking.depotCost.total - newPrice[aviaBooking.id] }} {{aviaBooking.depotCost.currency}}
              el-col(:span="4")
                el-row
                  span.blue Locator: {{aviaBooking.locator}}
                el-row
                  span.blue Alternative Locators: {{aLocators[aviaBooking.id]}}
              el-col(:span="8").right
                el-row
                  el-button(type="primary" plain).wrap General Info
                el-row
                  el-button.wrap(type="primary" plain @click="expandPc(aviaBooking.id)" :icon="expanded[aviaBooking.id] ? 'el-icon-arrow-up' : 'el-icon-arrow-down'") Alternatives
                el-row
                  el-button(type="primary" plain).wrap Addl options

            el-row(v-if="expanded[aviaBooking.id]")
              el-col
                el-button(v-if="scope.row.fakeBooking && scope.row.fakeBooking.status == 18" type="danger" plain @click="remoteSearch(aviaBooking.id, scope.row.orderCartId, true)").wrap Stop Search Attempts
                el-button(type="primary" plain @click="remoteSearch(aviaBooking.id, scope.row.orderCartId, false)").wrap New Search
                hr
                el-table(:data="remoteSearchData[aviaBooking.id]" border stripe)
                  el-table-column(label="...")
                    template(slot-scope="scope")
                      el-button(@click="openExtendedAlternatives(aviaBooking, scope.row.remoteRequestId)") More
                  el-table-column(label="Remote Request ID" prop="remoteRequestId" :id="remoteRequestId")
                  el-table-column(label="Created At")
                    template(slot-scope="scope")
                      span {{new Date(scope.row.createdAt) | moment("LLLL")}}
                  el-table-column(label="Best Price")
                    template(slot-scope="scope")
                      span {{scope.row.bestPrice}}
                  el-table-column(label="Booked Price")
                    template(slot-scope="scope")
                      span {{scope.row.bookedPrice}}
                  el-table-column(label="Booked Locator")
                    template(slot-scope="scope")
                      span {{scope.row.locators.join(",")}}
                  el-table-column(type="expand")
                    template(slot-scope="scs")
                      el-table(:data="alternative(aviaBooking.id, scs.row.remoteRequestId)" border stripe :row-class-name="tableRowAlternativeClassName" :cell-style="cellStyle")
                        el-table-column(label="-" prop="statusStr")
                        el-table-column(label="-" prop="configName")
                        el-table-column(label="stP" prop="stP" width="70px")
                        //- el-table-column(label="A" prop="colA" width="70px")
                        //- el-table-column(label="C" prop="colC" width="70px")
                        //- el-table-column(label="I" prop="colI" width="70px")
                        //- el-table-column(label="M" prop="colM" width="70px")
                        //- el-table-column(label="P" prop="colP" width="70px")
                        //- el-table-column(label="R" prop="colR" width="70px")
                        //- el-table-column(label="RD" prop="colRd")
                        //- el-table-column(label="S" prop="colS" )
                        //- el-table-column(label="C" prop="bspC" width="70px")
                        //- el-table-column(label="Meta" prop="meta" width="70px")
                        //- el-table-column(label="-" prop="formulaStr")
                        el-table-column(label="PC Formula Amt" width="100px" prop='formulaAmt')
                          template(slot-scope="dscs")
                            span(v-if="dscs.row.statusStr == 'alternative'") {{dscs.row.formulaAmt}}
                            span(v-if="dscs.row.statusStr == 'base'") N/A
                        el-table-column(label="All PC Formula Amt" width="100px" prop='allFormulaAmt')
                          template(slot-scope="dscs")
                            span(v-if="dscs.row.statusStr == 'base'") {{dscs.row.formulaAmt}}
                            span(v-if="dscs.row.statusStr == 'alternative'") -
                        el-table-column(label="Sess.Id" prop="sessionId")
                        el-table-column(label="Baggage" prop="baggage" width="70px")

                        el-table-column
                          template(slot-scope="sp")
                            el-button(type="primary" v-if="sp.row.statusStr == 'alternative'" @click="processVariant(sp.row.id, aviaBooking.id)" :id="sp.row.id") Book


</template>

<script>
import BaseTable from '@crud_view/table'
import EditCartCustomDiffDialog from './forms/edit_cart_custom_diff'

export default {
  extends: BaseTable,
  components: {
    EditCartCustomDiffDialog
  },
  data() {
    return {
      isVisible: false,
      loading: false,
      info: {},
      expanded: {},
      remoteSearchData: {},
      comments: {},
      centerDialogVisible: false,
      locator: 0,
      comment: '',
      aPrice: {},
      newPrice: {},
      aLocators: {},
      issueance: {},
      centerAlternativeDialogVisible: false,
      remoteRequestId: 0,
      extendedAlt: {},
      extendedAlternatives: {},
      selected: [],
      centerAlternativeMailDialogVisible: false,
      emailHtml: '',
      userMail: null,
      titleForAlternativesDialog: '',
      titleForEmailDialog: '',
      aviaBookingSearchCost: 0,
      bookingLocator: null,
      mailData: null,
      baseRecommendation: null,
      currentPc: null,
      cartItems: {},
      cartDiffRows: {},
      baseRecommendations: {},
      cartLoading: {},
      disableNewSearchCartButton: {},
      filterItemsForSegments: [
        { text: 'No transfers', value: 1 },
        { text: '1 transfer', value: 2 },
        { text: '2+ transfers', value: 3 },
      ]
    }
  },
  methods: {
    alternative(aviaBookingId, remoteRequestId) {
      var data = this.remoteSearchData[aviaBookingId] ? this.remoteSearchData[aviaBookingId].find((element) => element.remoteRequestId == remoteRequestId) : null;
      return data ? this._.filter(data.prices, ['isExtended', false]) : []
    },

    extendedAlternative(aviaBookingId) {
      return this.remoteSearchData[aviaBookingId][0] ? this._.filter(this.remoteSearchData[aviaBookingId][0].prices, ['isExtended', true]) : []
    },

    parseDate(int_time) {
      var dt = this.$moment.unix(int_time).format('L LT')
      return dt
    },

    getSupplier(aviaBooking) {
      if(this.issueance[aviaBooking.id] == undefined) {
        this.issueance[aviaBooking.id] = aviaBooking.externalInfo['block_issue']
      }

      return aviaBooking.supplier
    },

    processVariant(alternativeId, aviaBookingId = null) {
      this.$confirm('The booking will be created in GDS. Continue?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        this.bookVariant(alternativeId)
      })
    },

    addComment() {
      if (this.comment.length === 0) {
        return
      } else {
        this.proceedComment()
      }
    },

    async proceedComment() {
      try {
        const query = {
          __args: {
            locator: this.locator,
            comment: this.comment,
            table: 'cart'
          },
          id: true,
          createdAt: true
        }

        await this.graphql('addComment', query, 'mutation')

        this.$notify({
          title: 'Add a Comment',
          message: 'Comment added and synchronized with old admin panel',
          duration: 2600
        })

        this.comments[this.locator].unshift({
          data: this.comment,
          createdAt: this.$moment().format('LLL'),
          backendUser: {
            login: 'system'
          }
        })

        this.comment = ''
      } catch (e) {
        console.error(e)
      }
    },

    async bookVariant(alternativeId) {
      try {
        const query = {
          __args: {
            alternativeId: parseInt(alternativeId)
          }
        }

        await this.graphql('bookAlternative', query, 'mutation')

        this.$notify({
          title: 'Booking has started',
          message: 'Check the result in a few minutes',
          duration: 2600
        })
      } catch (e) {
        console.error(e)
      }
    },

    newSearchFromCard(cartItem){
      this.disableNewSearchCartButton[cartItem.locator] = true
      this.disableNewSearchCartButton = { ...this.disableNewSearchCartButton }
      let _self = this
      let orderItem = this.getOrderItemBy(cartItem.locator)
      let pc_id = orderItem.aviaBookings[cartItem.index].id
      this.remoteSearch(pc_id, orderItem.orderCartId, false).then(() => _self.getCart(cartItem.locator))
    },

    async remoteSearch(pc_id, cart_id, stop_research) {
      try {
        const query = {
          __args: {
            aviaBookingId: parseInt(pc_id),
            cartId: parseInt(cart_id),
            stopResearch: stop_research
          }
        }

        let result = await this.graphql('newRemoteSearch', query, 'mutation')

        let message = result;
        if(stop_research){
          message = 'ReSearch Attempts Stopped!'
        }

        this.$notify({
          title: 'New search',
          message: message,
          duration: 2600
        })
      } catch (e) {
        console.error(e)
      }
    },

    async expandPc(pc_id, onlySearch) {
      try {
        const query = {
          __args: {
            aviaBookingId: parseInt(pc_id)
          },
          remoteRequestId: true,
          createdAt: true,
          bestPrice: true,
          locators: true,
          bookedPrice: true,
          prices: {
            id: true,
            statusStr: true,
            colA: true,
            colC: true,
            colI: true,
            colM: true,
            colP: true,
            colR: true,
            colS: true,
            colRd: true,
            bspC: true,
            meta: true,
            stP: true,
            manualRate: true,
            sessionId: true,
            session: true,
            formulaAmt: true,
            formulaStr: true,
            configId: true,
            pnr: true,
            configName: true,
            baggage: true,
            isExtended: true,
            flightNumber: true,
            segments: true
          }
        }

        if (this.expanded.hasOwnProperty(pc_id)) {
          if(onlySearch === undefined) {
            delete this.expanded[pc_id]
          }
          delete this.remoteSearchData[pc_id]
        } else {
          this.remoteSearchData[pc_id] = await this.graphql('remoteSearches', query)
          if (this.remoteSearchData[pc_id][0]){
            this.baseRecommendations[this.getRecord(pc_id).locator] = this.baseRecommendation = this._.filter(this.remoteSearchData[pc_id][0].prices, ['statusStr', 'base'])[0]
          }
          if(onlySearch === undefined) {
            this.expanded[pc_id] = true
          }
        }

        if(onlySearch === undefined) {
          this.expanded = { ...this.expanded }
        }
        this.remoteSearchData = { ...this.remoteSearchData }
        this.getSumOfRebookingComponents(pc_id)
      } catch (e) {
        console.error(e)
      }
    },

    getSumOfRebookingComponents(bookingId, row) {
      var remoteRequests = this.remoteSearchData[bookingId]
      var newPrice = 0
      var possiblePrice = 0
      var aLocators = []

      if(remoteRequests != undefined) {
        remoteRequests.forEach((remoteRequest) => {
          remoteRequest.prices.forEach((price) => {
            if(price.statusStr == 'manual_book') {
              newPrice += price.formulaAmt
              aLocators.push(price.pnr)
            }

            if(price.statusStr == 'alternative' && (price.formulaAmt < possiblePrice || possiblePrice == 0)) {
              possiblePrice = price.formulaAmt
            }
          })
        })
      }

      this.newPrice[bookingId] = newPrice
      this.aPrice[bookingId] = possiblePrice
      this.aLocators[bookingId] = aLocators.join(', ')

      this.newPrice = { ...this.newPrice }
      this.aPrice = { ...this.aPrice }
      this.aLocators = { ...this.aLocators }
    },

    aviaBookingExpanded(row, expanded) {
      var expand = false
      expanded.forEach((expanded_book) => {
        if(expanded_book.id == row.id) {
          expand = true
        }
      })

      if(expand) {
        this.cartLoading[row.locator] = true
        row.aviaBookings.forEach((aviaBookig) => {
          this.expandPc(aviaBookig.id, true)
        })

        this._.delay(this.getCart, 200, row.locator)
      }else{
        row.aviaBookings.forEach((aviaBookig) => {
          delete this.expanded[aviaBookig.id]
        })
      }
    },

    generateRouteString(aviaBookingSegments) {
      var flight = []

      aviaBookingSegments.forEach((segment) => {
        flight.push(['<i class="el-icon-top-right" /> ' + segment.departure + '<small> ' + segment.departureDate + ' ' + segment.departureTime + ' </small>' +
        '<i class="el-icon-bottom-right" /> ' +
        segment.arrival + '<small> ' + segment.arrivalDate + ' ' + segment.arrivalTime + ' </small>'])
      })

      return flight.join('<small>&nbsp;</small>')
    },

    generateTripRoute(aviaBookings) {
      var route = []
      aviaBookings.forEach((avia_booking) => {
        avia_booking.aviaBookingSegments.forEach((segment) => {
          if (route[route.length - 1] === undefined) {
            route.push(segment.departure)
          } else {
            if (route[route.length - 1] !== segment.departure) {
              route.push(segment.departure)
            }
          }
          route.push(segment.arrival)
        })
      })
      return route.join('-')
    },

    getDepotTotal(aviaBookings) {
      var total = 0
      var currency

      aviaBookings.forEach((avia_booking) => {
        total += avia_booking.depotCost.total
        currency = avia_booking.depotCost.currency
      })

      return total + ' ' + currency
    },

    getRecommendedTotal() {
      return '-'
    },

    getLastCommentAuthor(locator) {
      if (this.comments[locator] === null || this.comments[locator].length === 0) {
        return '-'
      }
      var comment = this.comments[locator][0]

      return comment.backendUser.login + ' (' + this.$moment(comment.createdAt).format('L') + ')'
    },

    getLastCommentText(locator) {
      if (this.comments[locator] === null || this.comments[locator].length === 0) {
        return '-'
      }
      var comment = this.comments[locator][0]

      if (comment === null) {
        return '-'
      }

      return comment.data
    },

    setComments(booking, locator) {
      this.comments[locator] = []

      if (booking === undefined || booking === null) {
        return
      }

      if (booking.comments === undefined) {
        return
      }

      this.comments[locator] = booking.comments
      return
    },

    showComments(locator) {
      this.locator = locator
      this.centerDialogVisible = true
      this.comment = ''
    },

    tableRowClassName({ row, rowIndex }) {
      var booking = row.booking
      if (booking == null) {
        return ''
      } else {
        return 'status_row_' + row.booking.status
      }
    },

    tableRowAlternativeClassName({ row, rowIndex }) {
      return 'status_row_' + row.statusStr
    },

    tableRowClassNameComponent({ row, rowIndex }) {
      return 'grey'
    },

    cartTableRowClassName({ row, rowIndex }){
      return row.status
    },

    cellStyle({row, column}){
      if((row.statusStr === 'base' && column.property === 'formulaAmt') || (row.statusStr === 'alternative' && column.property === 'allFormulaAmt')){
        return { backgroundColor: 'lightblue' }
      }else{
        return {}
      }
    },

    paxString(passengers) {
      var paxes = {}
      passengers.forEach(function(x) { paxes[x.type] = (paxes[x.type] || 0) + 1 })

      var pax_strings = []
      for (var type in paxes) {
        pax_strings.push(paxes[type] + type)
      }

      return pax_strings.join('/')
    },

    changeData(bookingId) {
      var status = this.issueance[bookingId]
      var inital_status = !status

      var word = status ? 'stop' : 'renew'

      const h = this.$createElement
      this.$confirm('', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        message: h('p', null, [
          h('span', null, 'You '),
          h('span', { style: 'color: teal', class: 'bold' }, word),
          h('span', null, ' issue by robot. Are you sure?')
        ]),
        type: 'warning'
      }).then(() => {
        this.issueance = { ...this.issueance }

        this.updateTsIssue(bookingId, status)

        return this.issueance[bookingId]
      }).catch(() => {
        this.issueance[bookingId] = inital_status
        this.issueance = { ...this.issueance }
        return this.issueance[bookingId]
      })
    },

    async updateTsIssue(bookingId, state) {
      try {
        const query = {
          __args: {
            aviaBookingId: parseInt(bookingId),
            issueState: state
          }
        }

        await this.graphql('disableTsIssue', query, 'mutation')

        this.$notify({
          title: 'Update in progress',
          message: 'The update will take effect in a few seconds',
          duration: 2600
        })
      } catch (e) {
        console.error(e)
      }
    },

    isCartReadyForBooking(locator) {
      return  this.isCartFull(locator) && this.allCartItemsStatusEq(locator, 'created')
    },

    allCartItemsStatusEq(cartLocator, status){
      return this._.filter(this.cartItems[cartLocator].items, ['status', status]).length == this.cartItems[cartLocator].items.length
    },

    isCartFull(cartLocator){
      return this.cartItems[cartLocator].items.length == this.records.find((record) => record.locator == cartLocator).aviaBookings.length
    },

    getRecord(pcId){
      return this.records.find(function(record){
        return record.aviaBookings.find((pc) => pc.id == pcId)
      })
    },

    bookingHasAncillary(pcId) {
      return this.getRecord(pcId).booking.hasAncillaryServices
    },


    getBaseRecommendation(aviaBookingId, remoteRequestId){
      let base
      let search = this.remoteSearchData[aviaBookingId].find((item) => item.remoteRequestId == remoteRequestId)
      if (search){
        base = search.prices.find((price) => price.statusStr == 'base')
      }
      return base
    },

    async openExtendedAlternatives(aviaBooking, remoteRequestId){
      try {

        if (this.bookingHasAncillary(aviaBooking.id)){
          this.$message({
            message: 'Alternatives are not allowed for bookings with Ancillary, Upsale or Insurance',
            type: 'info'
          })

          return false
        }

        const query = {
            __args: {
              remoteRequestId: parseInt(remoteRequestId),
            },
            configId: true,
            configName: true,
            sessionId: true,
            formualaAmt: true,
            final: true,
            session: true,
            baggage: true,
            flightNumber: true,
            segments: true,
            priceComponentId: true,
            remoteOrderId: true,
            id: true
          }

        this.extendedAlternatives[remoteRequestId]  = await this.graphql('extendedAlternatives', query)

        let self = this

        let q = this._.find(this.records, function(booking){
          return self._.find(booking.aviaBookings, ['id', aviaBooking.id])
        })

        this.bookingLocator = q.locator
        this.currentPc      = aviaBooking

        this.baseRecommendations[this.bookingLocator] = this.baseRecommendation = this.getBaseRecommendation(aviaBooking.id, remoteRequestId)

        this.aviaBookingSearchCost  = aviaBooking.depotCost.total
        this.remoteRequestId        = remoteRequestId
        this.titleForAlternativesDialog = "Alternative Flights"
        this.centerAlternativeDialogVisible = true

      } catch (e) {
        console.error(e)
      }
    },

    //
    // Alternative table sorter
    //
    getSortedAlternativeTableData(remoteRequestId, primaryProp = 'formualaAmt', secondaryProp = 'segments') {
      // Clone the data array to avoid mutating the original
      let alternatives = this.extendedAlternatives[remoteRequestId]
      alternatives   ||= []
      return alternatives.slice().sort((a, b) => {
        // Primary sort by the specified property
        if (a[primaryProp] !== b[primaryProp]) {
          return a[primaryProp] - b[primaryProp];
        }

        // Secondary sort by item count in JSON array
        const aItemsCount = this.getTripPartsCount(a[secondaryProp]);
        const bItemsCount = this.getTripPartsCount(b[secondaryProp]);
        return aItemsCount - bItemsCount;
      });
    },

    //
    // Alternative table filters
    //
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    filterDataFor(column, remoteRequestId){
      return this.extendedAlternatives[remoteRequestId] && this._.uniq(this.extendedAlternatives[remoteRequestId].map(function(alternative){
              return alternative[column];
            })).map(function(columnValue){
              return {text: columnValue, value: columnValue}
            }) || {}
    },
    filterDataForClass(remoteRequestId){
      let self = this
      return this.extendedAlternatives[remoteRequestId] && this._.uniq(this.extendedAlternatives[remoteRequestId].map(function(alternative){
              return self.class_decorate(alternative.segments);
            })).map(function(columnValue){
              return {text: columnValue, value: columnValue}
            }) || {}
    },
    filterAlternativeClass(value, row){
      return this.class_decorate(row.segments) === value
    },
    filterMethodBySegments(value, row){
      const itemsCount = this.getTripPartsCount(row.segments)
      if (value === 3) {
        return itemsCount >= 3;
      }
      return itemsCount === value;
    },
    //
    // END: Alternative table filters
    //

    closeAlternativesDialog(){
      this.$refs.alternativesFilterTable.clearFilter()
    },

    getTripPartsCount(segments){
      return this._.sum(
          JSON.parse(segments).map(function(trip){
            return trip.length
          })
        )
    },

    segments_decorate(segments){
      let self = this
      return JSON.parse(segments).map(function(trip){ 
        
        return trip.map(function(segment){
          let [dmonth,dday,dhours,dminutes] = self.separateMDHM(segment.departure_time)
          let [amonth,aday,ahours,aminutes] = self.separateMDHM(segment.arrival_time)
          
          let day_month = dday + '.' + dmonth
          if( dmonth != amonth || dday != aday){
            day_month = [dday + '.' + dmonth, aday + '.' + amonth].join('-')
          }

          let hours_minutes = [dhours + ":" + dminutes, ahours + ":" + aminutes].join('-')
          let route = [segment.departure_city, segment.arrival_city].join('-')

          return [day_month, segment.supplier_code, segment.flight_number, route, hours_minutes].join(' ')
        }).join("    |    ")
      
      }).join('   =>  ')
    },

    separateMDHM(date_string){

      let [date, time]        = date_string.split(' ')
      let [day, month, year]  = date.split('.')
      let [hours, minutes]    = time.split(':')

      return [
          month,
          day,
          hours,
          minutes
        ]
    },

    class_decorate(segments){
      return JSON.parse(segments).map((trip) => (trip.map((segment) => segment.service_class)).join('-')).join(' | ')
    },

    generateMailFromCart(locator){
      this.selected = this.cartItems[locator].items.map((i) => ({id: i.id}))

      this.generateMailWithAlternatives()
    },

    async generateMailWithAlternatives(){
      this.centerAlternativeDialogVisible = false

      const query = {
        __args: {
          alternativeIds: this.selected.map((i) => i.id),
          showOrSend: 'show'
        }
      }

      this.mailData = await this.graphql('generateMailWithAlternatives', query)

      this.emailHtml = this.mailData.email_body
      this.titleForEmailDialog = this.mailData.user_email ? 'Alternative mail for ' + this.mailData.user_email : 'Mail with alternatives'
      this.centerAlternativeMailDialogVisible = true
    },

    async sendMailWithAlternatives(){
      const query = {
        __args: {
          alternativeIds: this.selected.map((i) => i.id),
          showOrSend: 'send'
        }
      }

      let result = await this.graphql('generateMailWithAlternatives', query)
      
      this.centerAlternativeMailDialogVisible = false

      this.$notify({
          title: 'Sending ...',
          message: result.error == null ? 'Email sent' : result.error,
          duration: 2600
        })

      this.mailData = null
    },

    async getCart(bookingLocator, childCall = false){
      this.cartLoading[bookingLocator] = true
      try {
        const query = {
          __args: {
            locator: bookingLocator
          },
          id: true,
          status: true,
          locator: true,
          createdAt: true,
          updatedAt: true,
          items: true,
          totals: true,
          details: true
        }

      let cart = await this.graphql('aviaBookingCartGet', query)

      if (!!cart){
        if (!!cart.items && cart.items.length > 0){
          this.cartItems[bookingLocator] = cart
          this.currentDiffRow(bookingLocator)
        }else{
          delete this.cartItems[bookingLocator]
        }
      }else{
        delete this.cartItems[bookingLocator]
      }

      this.cartItems = { ...this.cartItems }

      this.cartDiffRows = { ...this.cartDiffRows }

      this.cartLoading[bookingLocator] = false

      } catch (e) {
        this.cartLoading[bookingLocator] = false
        console.error(e)
      }
    },

    async addToCartSubmit(bookingLocator, alternativeId, pcIndex){
      try {
        const query = {
          __args: {
            alternativeId: parseInt(alternativeId),
            locator: bookingLocator,
            pcIndex: pcIndex
          }
        }

        await this.graphql('aviaBookingCartAdd', query, 'mutation')

        this.getCart(bookingLocator)

      } catch (e) {
        console.error(e)
      }
    },

    async removeFromCartSubmit(bookingLocator, alternativeId, pcIndex){
      try {
        const query = {
          __args: {
            alternativeId: parseInt(alternativeId),
            locator: bookingLocator,
            pcIndex: pcIndex
          }
        }

        await this.graphql('aviaBookingCartRemove', query, 'mutation')

        this.getCart(bookingLocator)

      } catch (e) {
        console.error(e)
      }
    },

    addToCart(extendedAlternative, bookingLocator, currentPc){
      this.cartLoading[bookingLocator] = true

      let booking = this._.find(this.records, ['locator', bookingLocator])
      let pcIndex = this._.findIndex(booking.aviaBookings, (pc) => pc.locator === currentPc.locator)

      this.centerAlternativeDialogVisible = false

      this.addToCartSubmit(bookingLocator, extendedAlternative.id, pcIndex)
    },

    disableAdding(bookingLocator){
      let result = true

      if (!this.cartItems[bookingLocator] || this.cartItems[bookingLocator].status == 'created'){
        result = false
      }

      return result
    },

    removeFromCard(row){
      this.$confirm('Do you realy want to delete alternative ID = '+row.id+' from cart?', 'Warning', {
        confirmButtonText: 'Agree',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.cartLoading[row.locator] = true
        this.removeFromCartSubmit(row.locator, row.id, row.index)
      })
    },

    async cartBookingProcess(bookingLocator){
      try {
        const query = {
          __args: {
            locator: bookingLocator
          }
        }

        let result = await this.graphql('aviaBookingCartBook', query, 'mutation')

        this.$notify({
          title: 'Start Booking ...',
          message: 'Status: ' + result,
          duration: 2600
        })

        this.getCart(bookingLocator)

      } catch (e) {
        console.error(e)
      }
    },

    cartBooking(bookingLocator){
      let not_all_pc_included = this._.filter(this.cartItems[bookingLocator].items, ['type', 'research_base']).length > 0
      if(not_all_pc_included){
        this.$confirm('Not all PC are included', {
          confirmButtonText: 'Ok',
          cancelButtonText: 'No',
          type: 'warning'
        }).then(() => {
          this.cartBookingProcess(bookingLocator)
        })
      }else{
        this.cartBookingProcess(bookingLocator)
      }
    },

    getOrderItemBy(locator){
      return this.records.find((record) => record.locator == locator)
    },

    currentDiffRow(locator){
      let _self = this
      let price_components = this.getOrderItemBy(locator).aviaBookings
      // -----
      let currentBaseAllPcFormulaPrice = this._.sumBy(
            price_components.map(function(aviaBooking){
                let prices = _self.getPrices(aviaBooking.id)
                return prices.length > 0 && prices.find((price) => price.statusStr == 'base') || {'formulaAmt': 0}
              }),
            'formulaAmt'
          )/price_components.length
      // -----
      let currentCartAllPcFormulaPrice = this.cartItems[locator] && this._.sumBy(this.cartItems[locator].items, 'price') || 0
      // -----
      let currentDiff = currentBaseAllPcFormulaPrice - currentCartAllPcFormulaPrice

      return this.cartDiffRows[locator] = [{
              base: this._.round(currentBaseAllPcFormulaPrice, 2),
              cart: this._.round(currentCartAllPcFormulaPrice, 2),
              diff: this._.round(currentDiff, 2),
            }]
    },

    getPrices(aviaBookingId, times = 3){
      let remoteSearchData = this.remoteSearchData[aviaBookingId]
      if (!remoteSearchData && times > 0){
          this._.delay(this.getPrices, 500, aviaBookingId, times - 1)
      }
      return remoteSearchData && remoteSearchData[0] && remoteSearchData[0].prices || []
    },

    reloadCart(cart) {
      this.cartItems[cart.locator] = cart
      this.cartItems = { ...this.cartItems }
    }

  }
}
</script>

<style lang="scss">
  .orders-list-table {
    .el-table tr {
      &.status_row_W{
        background: #80FFFF;
      }
      &.status_row_P{
        background: #9EFF7F;
      }
      &.status_row_PN{
        background: #FFE681;
      }
      &.status_row_PN_autotckt{
        background: #CCCC99;
      }
      &.status_row_CM{
        background: #E5CBF1;
      }
      &.status_row_CMO{
        background: #f1e0cb;
      }
      &.status_row_CMP{
        background: #cbd3f1;
      }
      &.status_row_CMW{
        background: #9EFF7F;
      }
      &.status_row_NP{
        background: #70AAE9;
      }
      &.status_row_WA{
        background: #B97A57;
      }
      &.status_row_LCP{
        background: #00AEE1;
      }
      &.status_row_manual_book{
        background: #e1f3d8;
      }
      &.grey{
        background: #F2F6FC;
        border-bottom: 3px solid white;
      }
      &.undefined{
        background: lightgoldenrodyellow;
        border-bottom: 3px solid white;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .orders-list-table {
    .blue{
      color: #409EFF;
    }
    .bold{
      font-weight: bold;
    }
    .upcase{
      text-transform: uppercase;
    }
    .small{
      font-size: smaller;
    }

    .wrap{
      &:last-child {
        margin-bottom: 5px;
      }
    }

    hr {
      border:none;
      border-top:1px dotted black;
      color:#fff;
      background-color:#fff;
      height:1px;
    }
    .short {
      display: inline-block;
      width: 180px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
    .pointer{
      cursor: pointer;
    }

    .el-dialog .el-row {
       margin-bottom: 20px;
       &:last-child {
         margin-bottom: 0;
       }
     }

    .el-card .el-row {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .el-card {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .right{
      text-align: right;
    }
  }
</style>
